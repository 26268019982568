<template>
  <div>
    <!--  侧边栏菜单  -->
    <div class="sidebar_wrapper">
      <header class="flex_con">
        <el-image
          v-if="url"
          style="height: 50px"
          :src="url"
          fit="contain"></el-image>
        <ch-icon v-if="!url" name="logoWhite"></ch-icon>
      </header>
      <div class="menu" >
        <sidebarMenu :menuData="resource"/>
      </div>
    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions} from 'vuex'
  import sidebarMenu from './sidebarMenu'
  export default {
    components: {sidebarMenu},
    data(){
      return{
        sidebarMenu:[],
      }
    },
    mounted() {
      this.getRouters()
    },
    computed: {
      ...mapGetters(['resource']),
      url: function () {
        if(this.company?.logoUrl){
          return this.company.logoUrl
        } else {
          return false
        }
      }
    },
    methods: {
      ...mapActions(['getRouters'])
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar_wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;

    >header {
      height: 70px;
      background-color: #3E7EFF;
      .CH_icon {
        width: 100px;
        height: 100px;
      }
    }

    .menu {
      flex: 1;
      overflow-y: scroll;
    }

  }

</style>
