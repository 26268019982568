<template>
  <div>
    <div class="sidebarMenu">
      <el-menu
        :router="true"
        @open="handleOpen"
        @close="handleClose"
        :unique-opened="true"
        :default-active="$route.name"
        background-color="#fff"
        text-color="303133"
      >
        <div class="menus" v-for="item of menuData" :key="item.id">

          <el-submenu :index="item.path" v-if="item.menuType == 'M' && item.children">

            <template  slot="title">
            <!--  chooseIcon(item, $route.path) -->
              <el-image
                style="height: 16px;margin-right:3px"
                :src="item.icon"
                fit="fill"></el-image>
              <span slot="title">{{ item.menuName }}</span>
            </template>

            <div v-for="i of item.children" :key="i.id">

              <el-submenu :index="i.path" v-if="i.menuType == 'M' && i.children">

                <template slot="title">
                  <span slot="title">{{i.menuName}}</span>
                </template>

                <div v-for="ic of i.children" :key="ic.id">

                  <el-submenu :index="ic.path" v-if="ic.menuType == 'M'">
                    <template slot="title">
                      <span slot="title" style="padding-left: 20px">{{ic.menuName}}</span>
                    </template>
                  </el-submenu>

                  <el-menu-item :index="ic.path" v-else>
                    <span slot="title">{{ic.menuName}}</span>
                  </el-menu-item>

                </div>

              </el-submenu>

              <el-menu-item :index="i.path" v-else>
                <span slot="title" >{{i.menuName}}</span>
              </el-menu-item>

            </div>

          </el-submenu>

          <el-menu-item
            :index="item.path"
            v-else-if="!item.children"
            :route="item"
          >
            <ch-icon :name="item.icon"/>
            <span slot="title">{{ item.menuName }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>


<script>
export default {
  props: ['menuData'],
  data() {
    return {
      imageOpen: '',
      imageClose: '',
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   console.log(this.menuData)
    // }, 1000)
  },
  methods: {
    //改变颜色
    handleOpen(key) {
      this.imageOpen = key;
    },
    handleClose(key) {
      this.imageClose = key;
    },
    // 一级菜单图标是否高亮
    // chooseIcon(item, route) {
    //   let n = 0 // 用于判断当前一级菜单下的二级菜单是否被点击
    //   // for (let i = 0; i < item.children.length; i++) {
    //   //   if (item.children[i].path === route) {
    //   //     n = 1
    //   //   }
    //   // }
    //   if(item.path === route){
    //     n = 1
    //   }
    //   if (n === 1) { // 被点击了，返回高亮图标
    //     return item.meta.aicon
    //   } else { // 未被点击，返回未高亮图标
    //     return item.meta.icon
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.sidebarMenu {
  ::v-deep .el-menu{
    border-right: none;
  }
  .custom_icon{
    margin-right: 5px;
  }
}
@media screen  and (max-width: 1440px) {
  .sidebarMenu {
    width: 150px !important;
  }
}
</style>
